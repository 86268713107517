"use client";

import styles from "./GetInTouch.module.scss";
import Image from "next/image";
import React, { ChangeEvent, FormEvent, useState } from "react";
import { z } from "zod";
import Button from "../Buttons/Button";
import { API_BASE_URL } from "@/config/constants";

const contactFormSchema = z.object({
  name: z
    .string()
    .min(2, { message: "Numele trebuie să conțină cel puțin 2 caractere." })
    .max(100, { message: "Numele poate avea maxim 100 de caractere." }),
  email: z.string().email({ message: "Adresa de email nu este validă." }),
  phoneNumber: z
    .string()
    .regex(/^\+?\d{7,15}$/, {
      message: "Numărul de telefon nu este valid.",
    })
    .optional()
    .or(z.literal("")),
  message: z
    .string()
    .min(10, { message: "Mesajul trebuie să conțină cel puțin 10 caractere." })
    .max(1000, { message: "Mesajul poate avea maxim 1000 de caractere." }),
});

const defaultFormData = {
  name: "",
  phoneNumber: "",
  email: "",
  message: "",
};

type DefaultErrors = {
  name?: string[] | undefined;
  phoneNumber?: string[] | undefined;
  email?: string[] | undefined;
  message?: string[] | undefined;
};
const defaultErrors = {
  name: undefined as string[] | undefined,
  phoneNumber: undefined as string[] | undefined,
  email: undefined as string[] | undefined,
  message: undefined as string[] | undefined,
} as DefaultErrors;

const GetInTouch = () => {
  const [formData, setFormData] = useState(defaultFormData);

  const [errors, setErrors] = useState(defaultErrors);
  const [status, setStatus] = useState({
    submitting: false,
    success: false,
    error: null as null | string,
  });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Clear the error for the current field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setStatus({ submitting: true, success: false, error: null });
    setErrors(defaultErrors);

    // Validate form data
    const validatedFields = contactFormSchema.safeParse(formData);

    if (!validatedFields.success) {
      // Extract errors for each field
      const fieldErrors = validatedFields.error.flatten().fieldErrors;

      setErrors(fieldErrors);
      setStatus({ submitting: false, success: false, error: null });
      return;
    }

    const data = validatedFields.data;

    try {
      // Send data to your backend API
      const res = await fetch(`${API_BASE_URL}/contact-submissions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: data }),
      });

      if (!res.ok) {
        // Handle server errors
        // const errorResponse = await res.json();
        throw new Error("A apărut o eroare la trimiterea mesajului.");
      }

      // Show success message
      setStatus({ submitting: false, success: true, error: null });
    } catch (error) {
      // Handle network or unexpected errors
      console.error("Error submitting contact form:", error);
      setStatus({
        submitting: false,
        success: false,
        error:
          (error as { message: string }).message ||
          "A apărut o eroare la trimiterea mesajului.",
      });
    }
  };

  return (
    <div id="contact-us-form" className={styles.wrapper}>
      <h3>Contactează-ne</h3>
      <div className={styles.row}>
        <div className={styles.col}>
          <form className={styles.form} onSubmit={handleSubmit} noValidate>
            <p className={styles.title}>Trimite-ne un mesaj</p>
            <span className={styles.inputWrapper}>
              <label htmlFor="name" className={styles.label}>
                Nume
              </label>
              <input
                id="name"
                name="name"
                type="text"
                placeholder="Numele tău complet"
                value={formData.name}
                onChange={handleChange}
                required
                disabled={status.submitting || status.success}
              />
              {errors.name && (
                <p className={styles.errorMessage}>{errors.name}</p>
              )}
            </span>
            <span className={styles.inputWrapper}>
              <label htmlFor="email" className={styles.label}>
                Adresa ta de email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                placeholder="exemplu@email.ro"
                value={formData.email}
                onChange={handleChange}
                required
                disabled={status.submitting || status.success}
              />
              {errors.email && (
                <p className={styles.errorMessage}>{errors.email}</p>
              )}
            </span>
            <span className={styles.inputWrapper}>
              <label htmlFor="phoneNumber" className={styles.label}>
                Numărul tău de telefon
              </label>
              <input
                id="phoneNumber"
                name="phoneNumber"
                type="tel"
                placeholder="0350 410-000"
                value={formData.phoneNumber}
                onChange={handleChange}
                disabled={status.submitting || status.success}
              />
              {errors.phoneNumber && (
                <p className={styles.errorMessage}>{errors.phoneNumber}</p>
              )}
            </span>
            <span className={styles.inputWrapper}>
              <label htmlFor="message" className={styles.label}>
                Mesaj
              </label>
              <textarea
                id="message"
                name="message"
                cols={30}
                rows={10}
                placeholder="Spune-ne cum te putem ajuta"
                value={formData.message}
                onChange={handleChange}
                required
                disabled={status.submitting || status.success}
              ></textarea>
              {errors.message && (
                <p className={styles.errorMessage}>{errors.message}</p>
              )}
            </span>
            <p className={styles.message}>
              Prin trimiterea formularului de contact, accepți termenii și
              condițiile.
            </p>
            {!status.success && (
              <Button
                text={
                  status.submitting
                    ? "Se trimite..."
                    : status.success
                    ? "Mesajul a fost trimis"
                    : "Trimite mesajul"
                }
                noRightIcon={status.success || status.submitting}
                disabled={status.submitting || status.success}
                type="submit"
              />
            )}

            {status.error && (
              <p className={styles.errorMessage}>{status.error}</p>
            )}
          </form>
          {status.success && (
            <div className={styles.successMessageContainer}>
              <Image
                src="/images/successMessage.svg"
                width={128}
                height={128}
                alt="Mesaj trimis cu succes"
              />
              <p className={styles.successMessage}>
                Mesajul a fost trimis cu succes.
              </p>
              <p className={styles.successMessage}>
                Îți mulțumim că ne-ai contactat!
              </p>
              <p className={styles.successMessage}>
                O să vă răspundem în cel mai scurt timp posibil.
              </p>
            </div>
          )}
        </div>
        <div className={styles.col}>
          <span className={styles.imgWrapper}>
            <Image src="/images/getInTouch.png" alt="about" fill />
          </span>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
