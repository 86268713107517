import React from "react";
import styles from "./ButtonBase.module.scss";
import { FaArrowRightLong } from "react-icons/fa6";

type Props = {
  ordinary?: boolean;
  leftSideImage?: React.ReactNode;
  text: string;
  noRightIcon?: boolean;
};

const ButtonBase = ({ ordinary, leftSideImage, text, noRightIcon }: Props) => {
  const additionalClassName = `${styles.btn} ${
    ordinary ? styles.ordinary : styles.primary
  }`;

  return (
    <div className={additionalClassName}>
      {leftSideImage && (
        <span className={styles.imgWrapper}>{leftSideImage}</span>
      )}{" "}
      <span className={styles.btnText}>{text}</span>
      {!noRightIcon && <FaArrowRightLong className={styles.arrow} />}
    </div>
  );
};

export default ButtonBase;
