import React, { CSSProperties } from "react";
import ButtonBase from "./ButtonBase/ButtonBase";

type Props = {
  ordinary?: boolean;
  leftSideImage?: React.ReactNode;
  text: string;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  onClick?: () => {};
  noRightIcon?: boolean;
  style?: CSSProperties;
};

const Button = (props: Props) => {
  const {
    ordinary,
    leftSideImage,
    text,
    disabled,
    onClick,
    noRightIcon,
    type = "button",
    style,
  } = props;
  return (
    <button type={type} disabled={disabled} onClick={onClick} style={style}>
      <ButtonBase
        text={text}
        leftSideImage={leftSideImage}
        ordinary={ordinary}
        noRightIcon={noRightIcon}
      />
    </button>
  );
};

export default Button;
